var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { createSlice } from "@reduxjs/toolkit";
var initialState = {};
var toastSlice = createSlice({
    name: "toasts",
    initialState: initialState,
    reducers: {
        loadingToastCreated: function (state, action) {
            var _a;
            var _b, _c;
            var _d = action.payload, component = _d.component, toastId = _d.toastId, requestId = _d.requestId;
            return __assign(__assign({}, state), (_a = {}, _a[component] = {
                toastId: toastId,
                requestIds: __spreadArray(__spreadArray([], ((_c = (_b = state[component]) === null || _b === void 0 ? void 0 : _b.requestIds) !== null && _c !== void 0 ? _c : []), true), [
                    requestId
                ], false)
            }, _a));
        },
        toastRequestResolved: function (state, action) {
            var _a = action.payload, component = _a.component, requestId = _a.requestId;
            var currentToast = state[component];
            if (currentToast) {
                currentToast.requestIds = currentToast.requestIds.filter(function (id) { return id !== requestId; });
                if (!currentToast.requestIds.length) {
                    delete state[component];
                }
            }
        }
    }
});
export var loadingToastCreated = (_a = toastSlice.actions, _a.loadingToastCreated), toastRequestResolved = _a.toastRequestResolved;
export default toastSlice.reducer;
