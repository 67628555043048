var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { loadingToastCreated, toastRequestResolved } from "@redux/slices/toastSlice";
import { getBaseComponentFromActionType, isApiThunkAction } from "@redux/api/createApiThunk";
import toast from "react-hot-toast";
import { createNewCompany } from "@redux/api/endpoints/companies";
import { updateSyndicatedCollectionPromotion } from "@redux/api/endpoints/admin";
import { updateCollectionCollaborators } from "@redux/api/endpoints/collections";
var DEFAULT_ERROR_MESSAGE = "An error occurred. Please try again.";
var DEFAULT_LOADING_MESSAGE = "Loading...";
var DEFAULT_SUCCESS_MESSAGE = "Saved";
/**
 * Temporary mapping for custom messages until all API calls are converted to thunks
 * (which have toast messages defined in their payload)
 */
var TOAST_MESSAGES = {
    loading: {
        DEFAULT: DEFAULT_LOADING_MESSAGE,
    },
    error: {
        DEFAULT: DEFAULT_ERROR_MESSAGE,
    },
    success: {
        DEFAULT: DEFAULT_SUCCESS_MESSAGE,
        COLLECTIONS_LINK_PHOTO_DATA: "Added to collections",
        TOGGLE_USER_PRIVATE_COLLECTION_ADMIN_OFF_DATA: "Updated",
        TOGGLE_USER_PRIVATE_COLLECTION_ADMIN_ON_DATA: "Updated",
        COLLECTIONS_UNLINK_PHOTO_DATA: "Removed",
        ALERT_CREATE_DATA: "Alert created",
        ALERT_DELETE_DATA: "Alert deleted",
        COLLECTION_CREATE_DATA: "Collection created",
        DELETE_DATA: "Deleted",
        REPORT_DATA: "Photo reported",
        USER_CHANGE_NAME_DATA: "Profile saved",
    }
};
var TOAST_TRIGGERS = [
    "ALERT_CREATE_DATA",
    "ALERT_DELETE_DATA",
    "ANALYTICS_REPORT_INCLUDE_API_DATA",
    "ANALYTICS_REPORT_EXCLUDE_API_DATA",
    "PUBLICATIONS_SET_SORT_SAVE_DATA",
    "USER_SAVE_FONT_SIZE_DATA",
    "USER_SAVE_CONTRAST_DATA",
    "USER_SET_PPT_LOGO_PREFERENCE_DATA",
    "USER_SET_PPT_COMPRESSION_PREFERENCE_DATA",
    "USER_SELECT_LANGUAGE_SAVE_DATA",
    "COLLECTION_TOGGLE_CURATION_DATA",
    "COLLECTION_CHANGE_COUNTRY_DATA",
    "COLLECTION_UPLOAD_COVER_DATA",
    "AUTOMATION_RULES_SAVE_TO_API_DATA",
    "COLLECTION_CHANGE_COLORS_DATA",
    "COLLECTION_CHANGE_COVER_DATA",
    "COLLECTION_CHANGE_NAME_DATA",
    "COLLECTION_CHANGE_SUMMARY_DATA",
    "COLLECTION_CREATE_DATA",
    "COLLECTIONS_LINK_PHOTO_DATA",
    "COLLECTIONS_UNLINK_PHOTO_DATA",
    "COMPANY_CATEGORIES_EDIT_DATA",
    "COMPANY_PARENTS_EDIT_DATA",
    "COMPANY_CHANNELS_EDIT_DATA",
    createNewCompany,
    "DELETE_DATA",
    "FEED_SET_DEFAULT_SORT_DATA",
    "HAWKEYE_DELETE_DATA",
    "HAWKEYE_APPROVE_DATA",
    "LOCATION_STORE_NUMBER_EDIT_DATA",
    "PUBLICATIONS_SET_SORT_SAVE",
    "PRIVATE_COLLECTION_CHANGE_COLORS_DATA",
    "PRIVATE_COLLECTION_CHANGE_COVER_DATA",
    "PRIVATE_COLLECTION_CHANGE_NAME_DATA",
    "PRIVATE_COLLECTION_CHANGE_PREVIEW_DATA",
    "PRIVATE_COLLECTION_CHANGE_SUMMARY_DATA",
    "PRIVATE_COLLECTION_CHANGE_DOWNGRADE_DATA",
    "REPORT_DATA",
    "SAVE_INCLUDED_CATEGORIES_DATA",
    "SAVE_NEW_PROFILE_PIC_DATA",
    updateCollectionCollaborators,
    updateSyndicatedCollectionPromotion,
    "TOGGLE_USER_PRIVATE_COLLECTION_ADMIN_OFF_DATA",
    "TOGGLE_USER_PRIVATE_COLLECTION_ADMIN_ON_DATA",
    "USER_CHANGE_NAME_DATA",
    "USER_SAVE_CARD_PREFS_DATA",
    "USER_SELECT_HOMEPAGE_PREFERENCE_SAVE_DATA",
    "USER_SET_LAYOUT_PREFERENCE_DATA",
    "USER_TOGGLES_PUBLISHER_STATUS_DATA",
    "WHITELIST_EMAIL_DOMAIN_DATA",
];
var createToast = function (_a) {
    var message = _a.message, toastType = _a.toastType, darkMode = _a.darkMode;
    var toastConfig = __assign({ style: {
            borderRadius: "10px",
            background: darkMode ? "#333" : "#FFF",
            color: darkMode ? "#FFF" : "#333"
        } }, (toastType !== "loading" && {
        duration: 1500
    }));
    return toast[toastType](message, toastConfig);
};
var getToastMessage = function (component, toastType) {
    return TOAST_MESSAGES[toastType][component] || TOAST_MESSAGES[toastType].DEFAULT;
};
var isToastTrigger = function (action) {
    return TOAST_TRIGGERS.some(function (trigger) {
        return typeof trigger === "string"
            ? action.type === trigger
            : action.type.startsWith(trigger.typePrefix);
    });
};
export var addToastListener = function (startAppListening) {
    startAppListening({
        predicate: isToastTrigger,
        effect: function (action, listenerApi) { return __awaiter(void 0, void 0, void 0, function () {
            var currentState, darkMode, component, _a, requestStatus, requestId, loadingToast, errorToast, successToast, existingToast, toastId, updatedToast;
            var _b;
            return __generator(this, function (_c) {
                currentState = listenerApi.getState();
                darkMode = (_b = currentState.dataUser) === null || _b === void 0 ? void 0 : _b.dark_mode;
                component = getBaseComponentFromActionType(action.type);
                if (isApiThunkAction(action)) {
                    _a = action.meta, requestStatus = _a.requestStatus, requestId = _a.requestId, loadingToast = _a.loadingToast, errorToast = _a.errorToast, successToast = _a.successToast;
                    existingToast = currentState.toasts[component];
                    if (requestStatus === "pending") {
                        if (existingToast) {
                            toast.dismiss(existingToast.toastId);
                        }
                        toastId = createToast({
                            message: loadingToast || getToastMessage(component, "loading"),
                            toastType: "loading",
                            darkMode: darkMode
                        });
                        listenerApi.dispatch(loadingToastCreated({
                            component: component,
                            requestId: requestId,
                            toastId: toastId
                        }));
                    }
                    else {
                        listenerApi.dispatch(toastRequestResolved({
                            component: component,
                            requestId: requestId
                        }));
                        updatedToast = listenerApi.getState().toasts[component];
                        if (existingToast && !updatedToast) {
                            toast.dismiss(existingToast.toastId);
                        }
                        if (requestStatus === "rejected") {
                            createToast({
                                message: errorToast || getToastMessage(component, "error"),
                                toastType: "error",
                                darkMode: darkMode
                            });
                        }
                        else {
                            createToast({
                                message: successToast || getToastMessage(component, "success"),
                                toastType: "success",
                                darkMode: darkMode
                            });
                        }
                    }
                }
                else {
                    createToast({
                        message: getToastMessage(component, "success"),
                        toastType: "success",
                        darkMode: darkMode
                    });
                }
                return [2 /*return*/];
            });
        }); }
    });
};
