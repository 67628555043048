var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a, _b;
import { logout } from "@redux/actions/logout";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import { hideModal, showModal } from "@redux/slices/modalSlice";
import { emptyObject } from "constants/variables";
import { isObject } from "shared/helpers/typeGuards";
import { getTypedEntries } from "shared/types/utilityTypes";
import { createOrUpdateQuestionCode, deleteQuestionCode, fetchQuestionCodeMetadata, fetchQuestionCodes } from "@redux/api/endpoints/admin";
import { Modals } from "screens/modal/Modal.constants";
import { searchForPrivateSpace } from "@redux/api/endpoints/privateCollections";
var EMPTY_QUESTION_CODE = {
    id: 0,
    name: "",
    photo_source_id: 0,
    access_type_id: 0,
    categories: [],
    tags: [],
    collections: [],
    private_collections: []
};
var emptyQuestionCodeFilter = {
    photo_source_id: [],
    access_type_id: [],
    categories: {},
    tags: {},
    collections: {},
    private_collections: {},
};
var initialQuestionCodeFilters = {
    existing: emptyQuestionCodeFilter,
    new: emptyQuestionCodeFilter
};
var initialState = {
    pcSearchResults: null,
    questionCodes: null,
    questionCodeMetadata: null,
    pendingQuestionCodes: {},
    questionCodeFilters: initialQuestionCodeFilters
};
var adminPortalSlice = createSlice({
    name: "adminPortal",
    initialState: initialState,
    reducers: {
        pcSearchCleared: function (state) {
            state.pcSearchResults = null;
        },
        questionCodeUpdateRequested: function (state, action) {
            state.pendingQuestionCodes[action.payload.id] = { data: action.payload };
        },
        questionCodeFilterSelected: function (state, action) {
            state.questionCodeFilters.new[action.payload.filter] = action.payload.selectedOptions;
        },
        questionCodeFiltersApplied: function (state) {
            state.questionCodeFilters.existing = state.questionCodeFilters.new;
        },
        questionCodeFiltersCleared: function (state) {
            state.questionCodeFilters.new = emptyQuestionCodeFilter;
        },
    },
    extraReducers: function (builder) {
        builder
            .addCase(searchForPrivateSpace.fulfilled, function (state, action) {
            state.pcSearchResults = action.payload;
        })
            .addCase(fetchQuestionCodes.fulfilled, function (state, action) {
            state.questionCodes = action.payload;
            state.questionCodeFilters = initialQuestionCodeFilters;
        })
            .addCase(fetchQuestionCodeMetadata.fulfilled, function (state, action) {
            state.questionCodeMetadata = action.payload;
        })
            .addCase(createOrUpdateQuestionCode.pending, function (state, action) {
            var pendingQuestion = state.pendingQuestionCodes[action.meta.arg.body.question_id];
            if (pendingQuestion) {
                pendingQuestion.requestId = action.meta.requestId;
            }
        })
            .addCase(createOrUpdateQuestionCode.rejected, function (state, action) {
            var _a;
            var question_id = action.meta.arg.body.question_id;
            var pendingQuestionCodes = state.pendingQuestionCodes;
            if (((_a = pendingQuestionCodes[question_id]) === null || _a === void 0 ? void 0 : _a.requestId) === action.meta.requestId) {
                delete pendingQuestionCodes[question_id];
            }
        })
            .addCase(createOrUpdateQuestionCode.fulfilled, function (state, action) {
            var _a;
            var questionCodes = state.questionCodes, pendingQuestionCodes = state.pendingQuestionCodes;
            var _b = action.payload, id = _b.id, private_collections = _b.private_collections;
            var question_id = action.meta.arg.body.question_id;
            if (questionCodes && ((_a = pendingQuestionCodes[question_id]) === null || _a === void 0 ? void 0 : _a.requestId) === action.meta.requestId) {
                questionCodes.by_id[id] = __assign(__assign({}, pendingQuestionCodes[question_id].data), { private_collections: private_collections, id: id });
                if (question_id === EMPTY_QUESTION_CODE.id) {
                    questionCodes.all_ids.unshift(id);
                }
                delete pendingQuestionCodes[question_id];
            }
        })
            .addCase(deleteQuestionCode.fulfilled, function (state, action) {
            if (state.questionCodes) {
                var question_id_1 = action.meta.arg.body.question_id;
                delete state.questionCodes.by_id[question_id_1];
                state.questionCodes.all_ids = state.questionCodes.all_ids.filter(function (id) { return id !== question_id_1; });
            }
        })
            .addCase(showModal, function (state, action) {
            if (action.payload.component === Modals.NEW_QUESTION_CODE) {
                delete state.pendingQuestionCodes[EMPTY_QUESTION_CODE.id];
            }
        })
            .addCase(hideModal, function (state) {
            delete state.pendingQuestionCodes[EMPTY_QUESTION_CODE.id];
            state.questionCodeFilters.new = state.questionCodeFilters.existing;
        })
            .addCase(logout, function () { return initialState; });
    },
    selectors: {
        selectQuestionCodes: function (sliceState) { var _a; return (_a = sliceState.questionCodes) === null || _a === void 0 ? void 0 : _a.by_id; },
        selectQuestionCodeIds: function (sliceState) { var _a; return (_a = sliceState.questionCodes) === null || _a === void 0 ? void 0 : _a.all_ids; },
        selectQuestionCodeMetadata: function (sliceState) { return sliceState.questionCodeMetadata; },
        selectNewQuestionCodeFilters: function (sliceState) { return sliceState.questionCodeFilters.new; },
        selectExistingQuestionCodeFilters: function (sliceState) { return sliceState.questionCodeFilters.existing; },
    }
});
export var pcSearchCleared = (_a = adminPortalSlice.actions, _a.pcSearchCleared), questionCodeUpdateRequested = _a.questionCodeUpdateRequested, questionCodeFilterSelected = _a.questionCodeFilterSelected, questionCodeFiltersApplied = _a.questionCodeFiltersApplied, questionCodeFiltersCleared = _a.questionCodeFiltersCleared;
export var selectQuestionCodes = (_b = adminPortalSlice.selectors, _b.selectQuestionCodes), selectQuestionCodeIds = _b.selectQuestionCodeIds, selectQuestionCodeMetadata = _b.selectQuestionCodeMetadata, selectNewQuestionCodeFilters = _b.selectNewQuestionCodeFilters, selectExistingQuestionCodeFilters = _b.selectExistingQuestionCodeFilters;
export default adminPortalSlice.reducer;
export var selectQuestionCodeById = function (state, questionId) {
    var _a, _b;
    return questionId
        ? (_b = (_a = selectQuestionCodes(state)) === null || _a === void 0 ? void 0 : _a[questionId]) !== null && _b !== void 0 ? _b : emptyObject
        : EMPTY_QUESTION_CODE;
};
export var selectQuestionCodeNames = createSelector([selectQuestionCodes], function (questionCodes) {
    return Object.values(questionCodes || {}).map(function (question) { return question.name.toUpperCase(); });
});
export var selectIsValidQuestionCodeName = createSelector([
    selectQuestionCodeNames,
    function (_state, name) { return name.toUpperCase(); },
    function (_state, _name, isNew) { return isNew; }
], function (currentNames, name, isNew) {
    return !isNew || !!name.trim() && !currentNames.includes(name.trim());
});
var getFiltersWithValues = function (filters) {
    return getTypedEntries(filters).reduce(function (acc, _a) {
        var _b;
        var filterType = _a[0], values = _a[1];
        return (__assign(__assign({}, acc), ((Array.isArray(values) && values.length ||
            isObject(values) && Object.keys(values).length) && (_b = {}, _b[filterType] = values, _b))));
    }, {});
};
export var selectPendingQuestionCodeFilters = createSelector([selectNewQuestionCodeFilters], function (filters) { return getFiltersWithValues(filters); });
export var selectAppliedQuestionCodeFilters = createSelector([selectExistingQuestionCodeFilters], function (filters) { return getFiltersWithValues(filters); });
export var selectFilteredQuestionCodes = createSelector([
    selectQuestionCodes,
    selectAppliedQuestionCodeFilters,
], function (questionCodes, filters) {
    if (questionCodes) {
        if (!Object.keys(filters).length) {
            return Object.values(questionCodes);
        }
        var filterEntries_1 = getTypedEntries(filters);
        return Object.values(questionCodes).filter(function (question) { return (filterEntries_1.every(function (_a) {
            var filter = _a[0], values = _a[1];
            var linkedData = question[filter];
            if (Array.isArray(linkedData) && isObject(values)) {
                return linkedData.some(function (link) { return link.id in values; });
            }
            else if (typeof linkedData === "number" && Array.isArray(values)) {
                return values.includes(linkedData);
            }
        })); });
    }
});
var selectFilteredQuestionCodeIds = createSelector([
    selectFilteredQuestionCodes,
    selectQuestionCodeIds,
], function (filteredQuestionCodes, allIds) {
    if (filteredQuestionCodes && (filteredQuestionCodes === null || filteredQuestionCodes === void 0 ? void 0 : filteredQuestionCodes.length) !== (allIds === null || allIds === void 0 ? void 0 : allIds.length)) {
        return filteredQuestionCodes
            .sort(function (a, b) {
            return a.name.localeCompare(b.name, undefined, {
                numeric: true, sensitivity: 'base'
            });
        })
            .map(function (match) { return match.id; });
    }
    return allIds;
});
export var selectFilteredQuestionCodesByName = createSelector([
    selectFilteredQuestionCodes,
    selectFilteredQuestionCodeIds,
    function (_state, searchQuery) { return searchQuery.toUpperCase(); }
], function (filteredQuestionCodes, filteredIds, query) {
    if (filteredQuestionCodes && filteredIds && query) {
        var matches = filteredQuestionCodes.filter(function (question) { return question.name.toUpperCase().startsWith(query); });
        if (matches.length !== filteredIds.length) {
            return matches
                .sort(function (a, b) {
                return a.name.localeCompare(b.name, undefined, {
                    numeric: true, sensitivity: 'base'
                });
            })
                .map(function (match) { return match.id; });
        }
    }
    return filteredIds;
});
