import {
  hawkeye,
  advertiseSignUpStatus,
  automationRules,
  cacheBreakers,
  checkEnterpriseTld,
  dataAddToCollectionPhotoCheck,
  dataCampaigns,
  dataCampaignsSettings,
  dataCollectionsPrivate,
  dataDirectory,
  dataDirectorySettings,
  dataEmbed,
  dataFeed,
  dataFeedSuggestionsLandingPage,
  dataFeedSuggestionsMain,
  dataFeedUntagged,
  dataFlyers,
  dataFlyersAnalytics,
  dataFlyersSettings,
  dataGalleryPage,
  dataGenerateReportPage,
  dataLandingPageAbout,
  dataLandingPageAnalytics,
  dataLandingPageLocations,
  dataLandingPageRVI,
  dataLandingPageRVISettings,
  dataLoginPreview,
  dataPendingUpload,
  dataPhotosForReviewer,
  dataPreviewPhotoOptions,
  dataRecentSearches,
  dataSearchByText,
  dataSearchQuery,
  dataSharePage,
  dataTagger,
  dataTrendingSearches,
  dataUser,
  dataViewer,
  dataViewerSettings,
  dateOfLastLogin,
  feedCurrentlyFetching,
  feedFilterRules,
  feedMultipleFlyerSelect,
  feedMultiplePhotoSelect,
  feedMultiplePlanogramSelect,
  feedSort,
  feedView,
  fetchReducer,
  fixPreference,
  industryReports,
  industryReportsTopics,
  lastVisitDirectory,
  lastVisitUser,
  loginData,
  mapZoom,
  newProductUpdates,
  onboardingCompleted,
  orientationStages,
  reportedList,
  showDraw,
  showDropzone,
  showFeedMoreMenu,
  showFeedNavMenu,
  showFeedCompanyTypeMenu,
  showFeedShareMenu,
  showFeedShiftMultipleSelect,
  showFeedSortMenu,
  showFeedTypeMenu,
  showFlyerFilterMenu,
  showHeader,
  showHeatmap,
  showKeyboardShortcuts,
  showMenuDropdown,
  showMainMenuDropdown,
  showFullscreenModal,
  showPlanogramMoreMenu,
  showRVIBrandMenu,
  showRVIDateMenu,
  showSearch,
  showSideMenu,
  showTagging,
  showUpload,
  showUploadProgress,
  showViewer,
  showViewerPog,
  studioSettings,
  trends,
  unsavedList,
  uploadLocation,
  userPreferences,
} from "reducers/index";

import { loadingBarReducer } from "react-redux-loading-bar";

// Root Reducer
export default function getRootReducerObject() {
  return {
    loadingBar: loadingBarReducer,
    automationRules,
    advertiseSignUpStatus,
    cacheBreakers,
    checkEnterpriseTld,
    dataAddToCollectionPhotoCheck,
    dataCampaigns,
    dataCampaignsSettings,
    dataCollectionsPrivate,
    dataDirectory,
    dataDirectorySettings,
    dataEmbed,
    dataFeed,
    dataFeedSuggestionsLandingPage,
    dataFeedSuggestionsMain,
    dataFeedUntagged,
    dataFlyers,
    dataFlyersAnalytics,
    dataFlyersSettings,
    dataGalleryPage,
    dataGenerateReportPage,
    dataLandingPageAbout,
    dataLandingPageAnalytics,
    dataLandingPageLocations,
    dataLandingPageRVI,
    dataLandingPageRVISettings,
    dataLoginPreview,
    dataPendingUpload,
    dataPhotosForReviewer,
    dataPreviewPhotoOptions,
    dataRecentSearches,
    dataSearchByText,
    dataSearchQuery,
    dataSharePage,
    dataTagger,
    dataTrendingSearches,
    dataUser,
    dataViewer,
    dataViewerSettings,
    dateOfLastLogin,
    feedCurrentlyFetching,
    feedFilterRules,
    feedMultipleFlyerSelect,
    feedMultiplePhotoSelect,
    feedMultiplePlanogramSelect,
    feedSort,
    feedView,
    fetchReducer,
    fixPreference,
    hawkeye,
    industryReports,
    industryReportsTopics,
    lastVisitDirectory,
    lastVisitUser,
    loginData,
    mapZoom,
    newProductUpdates,
    onboardingCompleted,
    orientationStages,
    reportedList,
    showDraw,
    showDropzone,
    showFeedCompanyTypeMenu,
    showFeedNavMenu,
    showFeedMoreMenu,
    showFeedShareMenu,
    showFeedShiftMultipleSelect,
    showFeedSortMenu,
    showFeedTypeMenu,
    showFlyerFilterMenu,
    showFullscreenModal,
    showHeader,
    showHeatmap,
    showKeyboardShortcuts,
    showMenuDropdown,
    showMainMenuDropdown,
    showRVIBrandMenu,
    showRVIDateMenu,
    showSearch,
    showSideMenu,
    showPlanogramMoreMenu,
    showTagging,
    showUpload,
    showUploadProgress,
    showViewer,
    showViewerPog,
    studioSettings,
    trends,
    unsavedList,
    uploadLocation,
    userPreferences,
  };
}
