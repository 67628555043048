import { createApiThunk } from "@redux/api/createApiThunk";
export var createPrivateSpace = createApiThunk({
    route: "private_collection/setup",
    component: "CREATE_PRIVATE_SPACE",
    method: "POST",
});
export var updatePrivateCollectionDashboard = createApiThunk({
    route: "private_collection/id/:landingPageId/dashboard/:itemType",
    component: "PRIVATE_COLLECTION_DASHBOARD_UPDATE",
    method: "POST"
});
export var addUserToPrivateSpace = createApiThunk({
    route: "private_collection/id/:pcSlug/whitelist_email",
    component: "ADD_USER_TO_PRIVATE_SPACE",
    method: "POST"
});
export var joinPrivateSpace = createApiThunk({
    route: "private_collection/admin/join",
    component: "JOIN_A_PRIVATE_SPACE",
    method: "POST"
});
export var searchForPrivateSpace = createApiThunk({
    route: "private_collection/search/:searchQuery",
    component: "SEARCH_FOR_A_PRIVATE_SPACE",
    method: "GET",
});
