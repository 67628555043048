var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from "react";
import ErrorMessage from "components/auth-options/error-message";
import { DebounceInput } from "react-debounce-input";
import Dropzone from "react-dropzone/dist/es/index";
import { Divider, Preview } from "screens/landing-page/settings";
import { H3 } from "constants/styled-components";
import { apiCall } from "shared/helpers/apiCall";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { Modals } from "screens/modal/Modal.constants";
import { showModal } from "@redux/slices/modalSlice";
import Loader from "components/loader";
import styled from "styled-components";
var CoverOption = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  transition: ", ";\n  cursor: pointer;\n  width: 20em;\n  max-width: 100%;\n  height: 10em;\n  margin: 0.5em;\n  border-radius: 5px;\n  background-image: url(\"", "\");\n  background-position: center;\n  background-size: cover;\n  opacity: ", ";\n  border: 1px solid ", ";\n  filter: grayscale(0.5) contrast(0.9);\n\n  &:hover {\n    filter: grayscale(0);\n  }\n"], ["\n  transition: ", ";\n  cursor: pointer;\n  width: 20em;\n  max-width: 100%;\n  height: 10em;\n  margin: 0.5em;\n  border-radius: 5px;\n  background-image: url(\"", "\");\n  background-position: center;\n  background-size: cover;\n  opacity: ", ";\n  border: 1px solid ", ";\n  filter: grayscale(0.5) contrast(0.9);\n\n  &:hover {\n    filter: grayscale(0);\n  }\n"])), function (props) { return props.theme.defaultTransition; }, function (props) { return props.imageUrl; }, function (props) { return props.selected ? 1 : 0.3; }, function (props) { return props.theme.grey4; });
var UploadPreview = styled(Preview)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  opacity: ", ";\n"], ["\n  opacity: ", ";\n"])), function (props) { return props.selected ? 1 : 0.3; });
var StyledDropzone = styled(Dropzone)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  height: 100%;\n  padding: 3em;\n  margin: 1em;\n  width: 80%;\n  cursor: pointer;\n  border: 1px dashed grey;\n  border-radius: 5px;\n  background: whitesmoke;\n  outline: none;\n"], ["\n  position: relative;\n  height: 100%;\n  padding: 3em;\n  margin: 1em;\n  width: 80%;\n  cursor: pointer;\n  border: 1px dashed grey;\n  border-radius: 5px;\n  background: whitesmoke;\n  outline: none;\n"])));
export var ChooseCoverPhoto = function (_a) {
    var onCoverSelect = _a.onCoverSelect, currentCoverUrl = _a.currentCoverUrl, initialQuery = _a.initialQuery;
    var dispatch = useAppDispatch();
    var _b = useState(false), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useState(initialQuery || ""), query = _c[0], setQuery = _c[1];
    var _d = useState(false), dropzoneActive = _d[0], setDropzoneActive = _d[1];
    var _e = useState(null), uploadedCover = _e[0], setUploadedCover = _e[1];
    var _f = useState([]), coverPhotoOptions = _f[0], setCoverPhotoOptions = _f[1];
    var _g = useState(currentCoverUrl || null), selectedCover = _g[0], setSelectedCover = _g[1];
    var modalIsOpen = useAppSelector(function (state) { return state.modal.isOpen; });
    var searchForCoverPhotos = function () { return __awaiter(void 0, void 0, void 0, function () {
        var options, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setIsLoading(true);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, apiCall({
                            method: "GET",
                            component: "COVER_PHOTO_OPTIONS",
                            route: "collection/cover_photo_options/".concat(query.replace("/", ""))
                        })];
                case 2:
                    options = _b.sent();
                    setCoverPhotoOptions(options);
                    return [3 /*break*/, 5];
                case 3:
                    _a = _b.sent();
                    setCoverPhotoOptions([]);
                    return [3 /*break*/, 5];
                case 4:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (query.length > 2) {
            searchForCoverPhotos();
        }
        else if (!query) {
            setCoverPhotoOptions([]);
            setSelectedCover(uploadedCover);
        }
    }, [query]);
    useEffect(function () {
        return function () {
            if (uploadedCover) {
                URL.revokeObjectURL(uploadedCover.preview);
            }
        };
    }, []);
    var selectedCoverIsOption = useMemo(function () {
        return typeof selectedCover === "string" && coverPhotoOptions.some(function (option) { return option.img === selectedCover; });
    }, [selectedCover, coverPhotoOptions]);
    var handleCoverSelect = function (cover) {
        setSelectedCover(cover);
        onCoverSelect(cover);
    };
    var handleCoverUpload = function (_a) {
        var uploadedCover = _a[0];
        if (uploadedCover) {
            setUploadedCover(uploadedCover);
            handleCoverSelect(uploadedCover);
        }
        else if (!modalIsOpen) {
            dispatch(showModal({
                component: Modals.FAILED_UPLOAD,
                data: { reason: "image" }
            }));
        }
        else { // Use alert to preserve progress of pipeline creation modal
            window.alert("Sorry, only image files can be uploaded here");
        }
    };
    return (_jsxs(Divider, { children: [_jsx(Divider, { children: uploadedCover ? (_jsx(Divider, { children: _jsx(UploadPreview, { src: uploadedCover.preview, selected: selectedCover === uploadedCover, onClick: function () { return handleCoverSelect(uploadedCover); } }) })) : (_jsx(StyledDropzone, { accept: ["image/*"], onDrop: handleCoverUpload, onDragEnter: function () { return setDropzoneActive(true); }, onDragLeave: function () { return setDropzoneActive(false); }, onDropRejected: function () { return setUploadedCover(null); }, children: dropzoneActive ? (_jsx("div", { children: "Drop files..." })) : (_jsx("div", { children: "Click to upload" })) })) }), _jsx(Divider, { children: _jsx("p", { children: "OR" }) }), _jsxs(Divider, { children: [_jsx(H3, { children: "Select from one of our cover photos" }), _jsx(DebounceInput, { type: "text", name: "query", autoComplete: "off", debounceTimeout: 400, placeholder: "Search for a new cover photo...", onChange: function (e) { return setQuery(e.target.value); } }), isLoading && (_jsx(Divider, { children: _jsx(Loader, { item: "photos", size: "2em" }) })), coverPhotoOptions.length ? (coverPhotoOptions.map(function (option) { return (_jsx(CoverOption, { tabIndex: 0, imageUrl: option.img, onClick: function () { return handleCoverSelect(option.img); }, selected: !selectedCoverIsOption || selectedCover === option.img }, option.img)); })) : query.length > 2 && !isLoading && (_jsx(ErrorMessage, { error: "No photos found for \"".concat(query, "\"") }))] })] }));
};
var templateObject_1, templateObject_2, templateObject_3;
