var _a, _b;
import { getBaseComponentFromActionType, getComponentName, isApiThunkAction } from "@redux/api/createApiThunk";
import { createSlice } from "@reduxjs/toolkit";
var initialState = {};
var apiCallsSlice = createSlice({
    name: "apiCalls",
    initialState: initialState,
    reducers: {
        clearApiCallStatus: function (state, action) {
            var _a;
            var _b = action.payload, component = _b.component, requestId = _b.requestId;
            if (((_a = state[component]) === null || _a === void 0 ? void 0 : _a.requestId) === requestId) {
                delete state[component];
            }
        },
        clearApiCallError: function (state, action) {
            var _a;
            if ((_a = state[action.payload]) === null || _a === void 0 ? void 0 : _a.error) {
                delete state[action.payload];
            }
        }
    },
    extraReducers: function (builder) {
        builder
            .addMatcher(isApiThunkAction, function (state, action) {
            var meta = action.meta, payload = action.payload;
            var component = meta.component, requestId = meta.requestId, requestStatus = meta.requestStatus, takeLatest = meta.takeLatest;
            switch (requestStatus) {
                case "pending":
                    state[component] = {
                        fetching: true,
                        error: null,
                        requestId: requestId
                    };
                    break;
                case "fulfilled":
                    if (state[component]) {
                        if (!takeLatest || requestId === state[component].requestId) {
                            state[component].fetching = false;
                            state[component].error = false;
                        }
                    }
                    break;
                case "rejected": {
                    var componentName = component || getComponentName(getBaseComponentFromActionType(action.type), meta.arg);
                    if (state[componentName]) {
                        if (!takeLatest || requestId === state[componentName].requestId) {
                            state[componentName].fetching = false;
                            state[componentName].error = payload;
                        }
                    }
                    break;
                }
            }
        });
    },
    selectors: {
        selectAPICallIsFetching: function (sliceState, component) { var _a; return ((_a = sliceState[component]) === null || _a === void 0 ? void 0 : _a.fetching) || false; },
        selectAPICallIsSuccess: function (sliceState, component) { return !!sliceState[component] && !sliceState[component].fetching && sliceState[component].error === false; },
        selectAPICallIsError: function (sliceState, component) { var _a; return !!((_a = sliceState[component]) === null || _a === void 0 ? void 0 : _a.error); },
        selectAPICallError: function (sliceState, component) { var _a; return !!((_a = sliceState[component]) === null || _a === void 0 ? void 0 : _a.error) && sliceState[component].error; },
    }
});
export var clearApiCallStatus = (_a = apiCallsSlice.actions, _a.clearApiCallStatus), clearApiCallError = _a.clearApiCallError;
export var selectAPICallIsFetching = (_b = apiCallsSlice.selectors, _b.selectAPICallIsFetching), selectAPICallIsSuccess = _b.selectAPICallIsSuccess, selectAPICallIsError = _b.selectAPICallIsError, selectAPICallError = _b.selectAPICallError;
export default apiCallsSlice.reducer;
