var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { isRejectedWithValue, isAsyncThunkAction, } from "@reduxjs/toolkit";
import { apiCall } from "shared/helpers/apiCall";
import { clearApiCallStatus } from "@redux/slices/apiCallsSlice";
import { createAppAsyncThunk } from "@redux/middleware/thunkMiddleware";
import { isObject } from "shared/helpers/typeGuards";
import { destroyLoginCookieAndLocalStorage } from "constants/functions";
import { logout } from "@redux/actions/logout";
import { delay } from "shared/helpers/delay";
export var createApiAsyncThunk = createAppAsyncThunk.withTypes();
var applyRouteParams = function (route, routeParams) {
    if (!route.includes(":") || !routeParams)
        return route;
    return route.replace(/:(\w+)/g, function (placeholder, param) {
        return routeParams[param]
            ? encodeURIComponent(routeParams[param])
            : placeholder;
    });
};
export var getComponentName = function (baseComponent, _a) {
    var _b = _a === void 0 ? {} : _a, componentSuffix = _b.componentSuffix;
    return componentSuffix ? "".concat(baseComponent, "_").concat(componentSuffix) : baseComponent;
};
var transformBasePayload = function (base, dynamic) {
    var route = base.route, component = base.component;
    if (!isObject(dynamic)) {
        return base;
    }
    return __assign(__assign({}, base), { route: applyRouteParams(route, dynamic.routeParams), component: getComponentName(component, dynamic) });
};
export var createApiThunk = function (basePayload, options) {
    return createApiAsyncThunk("api/".concat(basePayload.component), function (dynamicPayload_1, _a) { return __awaiter(void 0, [dynamicPayload_1, _a], void 0, function (dynamicPayload, _b) {
        var _c, refetchThunk, transformed, _d, _e, useRefetch, dynamic, finalizedPayload, response, error_1, _f, _g, status, message, data;
        var dispatch = _b.dispatch, requestId = _b.requestId, fulfillWithValue = _b.fulfillWithValue, rejectWithValue = _b.rejectWithValue;
        return __generator(this, function (_h) {
            switch (_h.label) {
                case 0:
                    _c = transformBasePayload(basePayload, dynamicPayload), refetchThunk = _c.refetchThunk, transformed = __rest(_c, ["refetchThunk"]);
                    _d = dynamicPayload !== null && dynamicPayload !== void 0 ? dynamicPayload : { useRefetch: true }, _e = _d.useRefetch, useRefetch = _e === void 0 ? true : _e, dynamic = __rest(_d, ["useRefetch"]);
                    _h.label = 1;
                case 1:
                    _h.trys.push([1, 5, , 6]);
                    if (transformed.route.includes(":")) {
                        throw new Error("Missing route parameters for ".concat(transformed.route.match(/(:\w+)/g)));
                    }
                    finalizedPayload = __assign(__assign({}, transformed), dynamic);
                    return [4 /*yield*/, apiCall(finalizedPayload)];
                case 2:
                    response = _h.sent();
                    setTimeout(function () {
                        dispatch(clearApiCallStatus({
                            component: transformed.component,
                            requestId: requestId
                        }));
                    }, 1000);
                    if (!transformed.component.includes("IMPERSONATE_USER")) return [3 /*break*/, 4];
                    dispatch(logout());
                    return [4 /*yield*/, delay(500)];
                case 3:
                    _h.sent();
                    _h.label = 4;
                case 4:
                    if (refetchThunk && useRefetch) {
                        dispatch(refetchThunk());
                    }
                    return [2 /*return*/, fulfillWithValue(response, transformed)];
                case 5:
                    error_1 = _h.sent();
                    _f = error_1, _g = _f.status, status = _g === void 0 ? 500 : _g, message = _f.message, data = _f.data;
                    if (status === 401 && typeof data === "string" && data.includes("auth_failure")) {
                        destroyLoginCookieAndLocalStorage();
                        dispatch(logout());
                    }
                    return [2 /*return*/, rejectWithValue({ status: status, message: message, data: data }, transformed)];
                case 6: return [2 /*return*/];
            }
        });
    }); }, __assign(__assign({}, options), { getPendingMeta: function (_a) {
            var arg = _a.arg;
            return transformBasePayload(basePayload, arg);
        } }));
};
export var isApiThunkAction = function (action) {
    return isAsyncThunkAction(action) && action.type.startsWith("api/");
};
export var isApiThunkFailure = function (action) {
    return isRejectedWithValue(action) && action.type.startsWith("api/");
};
export var getBaseComponentFromActionType = function (actionType) {
    return actionType.replace(/^api\/(\S+)\/(?:pending|fulfilled|rejected)$/, "$1");
};
